<script>
	import { _, locale } from 'svelte-i18n';
	import { data as profileData } from '$src/stores.js';
	import CloseIcon from '$lib/icon/CloseIcon.svelte';
	import VerifyName from '$lib/VerifyName.svelte';
	import Modal from '$lib/modal/Modal.svelte';
	import dayjs from 'dayjs';
	import relativeTime from 'dayjs/plugin/relativeTime';
	import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
	import 'dayjs/locale/ar';
	import 'dayjs/locale/fr';
	import 'dayjs/locale/es';
	import 'dayjs/locale/hi';
	import 'dayjs/locale/de';
	import { putName } from '$utils/api.js';
	import EditIcon from '$lib/icon/EditIcon.svelte';

	dayjs.extend(preParsePostFormat);
	dayjs.extend(relativeTime);

	export let editMode = false;
	export let toggleEdit = () => {};

	let showAddNameModal = false;

	let addFullNameAjax = false;
	async function addFullName(value) {
		addFullNameAjax = true;
		try {
			const { names } = await putName('name', value);
			$profileData.profile.names = names;
			showAddNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addFullNameAjax = false;
		}
	}
</script>

<section class="w-full z-[35] bg-[#ebebeb] dark:bg-[#252525]">
	<!-- saturate(180%) blur(20px) -->
	<div class="px-3 py-4 max-w-2xl container mx-auto flex justify-between items-center">
		<div class="flex flex-col items-start">
			{#if $profileData.profile?.names?.length}
				<h1 class="text-xl font-medium">{$profileData.profile.names[0]}</h1>
			{:else}
				<div class="relative">
					<button
						on:click={() => (showAddNameModal = true)}
						class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add full name')}</button
					>

					{#if showAddNameModal}
						<Modal
							class="top-12 !w-[16rem]"
							position="left"
							on:close={() => (showAddNameModal = false)}
						>
							<VerifyName type="name" addName={addFullName} ajaxRequest={addFullNameAjax} />
						</Modal>
					{/if}
				</div>
			{/if}
			<h2 class="opacity-80 text-sm">
				{$_('Member since {date}', {
					values: {
						date: dayjs($profileData.profile.createdAt).locale($locale).format('MMMM DD, YYYY')
					}
				})}
			</h2>
		</div>

		<button
			data-test="profile-edit-btn"
			on:click={toggleEdit}
			class="px-2 inline-flex truncate items-center justify-center w-24 h-8 btn-border rounded-full gap-x-1"
		>
			{#if editMode}
				<CloseIcon />
				<span class="truncate">{$_('Close')}</span>
			{:else}
				<EditIcon />
				<span class="truncate">{$_('Edit')}</span>
			{/if}
		</button>
	</div>
</section>
